import Pressuru from 'url:../assets/fonts/Pressuru/Pressuru.ttf';
import OswaldRegular from 'url:../assets/fonts/Oswald/Oswald-Regular.ttf';
import OswaldBold from 'url:../assets/fonts/Oswald/Oswald-Bold.ttf';
import RobotoRegular from 'url:../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoBold from 'url:../assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoCondensedRegular from 'url:../assets/fonts/RobotoCondensed/RobotoCondensed-Regular.ttf';
import RobotoCondensedBold from 'url:../assets/fonts/RobotoCondensed/RobotoCondensed-Bold.ttf';
import CourierPrimeRegular from 'url:../assets/fonts/CourierPrime/CourierPrime-Regular.ttf';
import CourierPrimeBold from 'url:../assets/fonts/CourierPrime/CourierPrime-Bold.ttf';
import OpenSansRegular from 'url:../assets/fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansBold from 'url:../assets/fonts/OpenSans/OpenSans-Bold.ttf';
import NotoSansSCRegular from 'url:../assets/fonts/NotoSansSC/NotoSansSC-Regular.ttf';
import NotoSansSCBold from 'url:../assets/fonts/NotoSansSC/NotoSansSC-Bold.ttf';
import NotoSansTCRegular from 'url:../assets/fonts/NotoSansTC/NotoSansTC-Regular.ttf';
import NotoSansTCBold from 'url:../assets/fonts/NotoSansTC/NotoSansTC-Bold.ttf';
import NotoSerifKR from 'url:../assets/fonts/NotoSerifKR/NotoSerifKR-Regular.ttf';
import YousheHaoshenti from 'url:../assets/fonts/YousheHaoshenti/YSHaoShenTi.ttf'
import FangzhengZhuziMingchao from 'url:../assets/fonts/FangzhengZhuziMingchao/FZFWZhuZiABiaoTMCJW.TTF'
import FangzhengKai from 'url:../assets/fonts/FangzhengKai/FZKTK.ttf';
import FangzhengFangSong from 'url:../assets/fonts/FangzhengFangSong/FZFSK.ttf';
import FOTMatissePro from 'url:../assets/fonts/FOT-Matisse-Pro/FOT-Matisse-Pro-EB.otf'


export const customFonts = [
  {
    name: '黑体-简，常规（思源）',
    label: '黑体-简，常规（思源）',
    path: NotoSansSCRegular,
    style: 'normal',
    weight: '200'
  },
  {
    name: '黑体-简，粗体（思源）',
    label: '黑体-简，粗体（思源）',
    path: NotoSansSCBold,
    style: 'normal',
    weight: '200'
  },
  {
    name: '圆体-简（优舍好身体）',
    label: '圆体-简（优舍好身体）',
    path: YousheHaoshenti,
    style: 'normal',
    weight: '200'
  },
  {
    name: '方正FW筑紫明朝-简',
    label: '方正FW筑紫明朝-简',
    path: FangzhengZhuziMingchao,
    style: 'normal',
    weight: '200'
  },
  {
    name: '楷体（方正）',
    label: '楷体（方正）',
    path: FangzhengKai,
    style: 'normal',
    weight: '200'
  },
  {
    name: '仿宋（方正）',
    label: '仿宋（方正）',
    path: FangzhengFangSong,
    style: 'normal',
    weight: '200'
  },
  {
    name: '黑体-繁，常规（思源）',
    label: '黑体-繁，常规（思源）',
    path: NotoSansTCRegular,
    style: 'normal',
    weight: '200'
  },
  {
    name: '黑体-繁，粗体（思源）',
    label: '黑体-繁，粗体（思源）',
    path: NotoSansTCBold,
    style: 'normal',
    weight: '200'
  },
  {
    name: 'FOT Matisse EB Pro',
    label: 'FOT Matisse EB Pro',
    path: FOTMatissePro,
    style: 'normal',
    weight: '200'
  },
  {
    name: 'Noto Serif KR',
    label: 'Noto Serif KR',
    path: NotoSerifKR,
    style: 'normal',
    weight: '200'
  },
  { name: 'Pressuru', label: 'Pressuru', path: Pressuru, style: 'normal', weight: '400' },
  { name: 'Oswald-Regular', label: 'Oswald', path: OswaldRegular, style: 'normal', weight: '400' },
  { name: 'Oswald-Bold', label: 'Oswald Bold', path: OswaldBold, style: 'normal', weight: '700' },
  { name: 'Roboto-Regular', label: 'Roboto', path: RobotoRegular, style: 'normal', weight: '400' },
  { name: 'Roboto-Bold', label: 'Roboto Bold', path: RobotoBold, style: 'normal', weight: '700' },
  {
    name: 'RobotoCondensed-Regular',
    label: 'Roboto Condensed',
    path: RobotoCondensedRegular,
    style: 'normal',
    weight: '400'
  },
  {
    name: 'RobotoCondensed-Bold',
    label: 'Roboto Condensed Bold',
    path: RobotoCondensedBold,
    style: 'normal',
    weight: '700'
  },
  {
    name: 'CourierPrime-Regular',
    label: 'Courier Prime',
    path: CourierPrimeRegular,
    style: 'normal',
    weight: '400'
  },
  {
    name: 'CourierPrime-Bold',
    label: 'Courier Prime Bold',
    path: CourierPrimeBold,
    style: 'normal',
    weight: '700'
  },
  {
    name: 'OpenSans-Regular',
    label: 'Open Sans',
    path: OpenSansRegular,
    style: 'normal',
    weight: '400'
  },
  {
    name: 'OpenSans-Bold',
    label: 'Open Sans Bold',
    path: OpenSansBold,
    style: 'normal',
    weight: '400'
  }
];

export const loadCustomFont = async (name, path, options = {}) => {
  try {
    const font = new FontFace(name, `url(${path})`, { ...options });
    await font.load();
    document.fonts.add(font);
  } catch (err) {
    console.error(err);
  }
};
